import { render, staticRenderFns } from "./HorizontalNavMenuItems.vue?vue&type=template&id=4b45525e&scoped=true"
import script from "./HorizontalNavMenuItems.vue?vue&type=script&lang=js"
export * from "./HorizontalNavMenuItems.vue?vue&type=script&lang=js"
import style0 from "./HorizontalNavMenuItems.vue?vue&type=style&index=0&id=4b45525e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b45525e",
  null
  
)

export default component.exports